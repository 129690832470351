import React, { Fragment } from "react"
import { capitalize, find, get, size, uniqueId } from "lodash"
import { Link } from "gatsby"
import {
  Breadcrumb,
  Carousel,
  Col,
  Descriptions,
  Image,
  PageHeader,
  Row,
  Tag,
} from "antd"

import SiteLayout from "../components/site-layout"
import Seo from "../components/seo"
import { getTypeColor } from "../utils/utils"

const ProjectPage = ({ location, pageContext }) => {
  /**
   * PROPS
   */
  const { blocks, attachedMedia } = pageContext

  /**
   * METHODS
   */
  const getContent = blocks => {
    const content = find(blocks, { tagName: "p" })

    return content?.innerHtml
  }

  const getDetailList = detailType => {
    const detail = get(pageContext, detailType)
    const color = getTypeColor(detailType)

    if (!detail || size(detail) === 0) return null

    return (
      <Descriptions.Item
        label={capitalize(detailType)}
        contentStyle={{ display: "block" }}
      >
        {detail.map(item => (
          <Tag key={uniqueId("tag")} color={color}>
            {item}
          </Tag>
        ))}
      </Descriptions.Item>
    )
  }

  /**
   * VARS
   */
  const pageTitle = get(pageContext, "title")
  const skillListElem = getDetailList("skills")
  const languageListElem = getDetailList("languages")
  const frameworkListElem = getDetailList("frameworks")
  const libraryListElem = getDetailList("libraries")
  const mediaListElem = getDetailList("media")

  const content = getContent(blocks)

  /**
   * RENDER
   */
  return (
    <SiteLayout location={location}>
      <Seo title={`Project - ${pageTitle}`} />
      <PageHeader
        className="content-page-header"
        title={get(pageContext, "title")}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Portfolio</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Row justify="space-between" gutter={16}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <p
              dangerouslySetInnerHTML={{ __html: content }}
              key={uniqueId("p")}
            />
            <Descriptions title="" column={1}>
              <Descriptions.Item label="Project Role">
                {get(pageContext, "role")}
              </Descriptions.Item>
              {skillListElem}
              {languageListElem}
              {frameworkListElem}
              {libraryListElem}
              {mediaListElem}
            </Descriptions>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Carousel autoplay>
              {attachedMedia.map(media => (
                <Fragment key={uniqueId("img")}>
                  <Image src={media} />
                </Fragment>
              ))}
            </Carousel>
          </Col>
        </Row>
      </PageHeader>
    </SiteLayout>
  )
}

export default ProjectPage
